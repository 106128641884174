export const PI = 3.1415;
export function clamp(x, min, max) {
    if (min > max)
        throw Error(`Clamp: min cannot be greater then max.\nInput: min = ${min}, max = ${max}`);
    if (x < min)
        return min;
    if (x > max)
        return max;
    return x;
}
export function angleToRadians(angle) {
    if (angle == null) {
        return NaN;
    }
    return (angle * PI) / 180;
}
export function getPointOnCircle(radius, angle) {
    const rad = angleToRadians(angle);
    return new Point(radius * Math.cos(rad), radius * Math.sin(rad));
}
export function normalize(vector) {
    return vector.divide(vector.length);
}
// Returns a normalized direction from the origin of a circle
export function angleToDirection(angle) {
    const directionY = Math.cos(angleToRadians(angle));
    const directionX = Math.sin(angleToRadians(angle));
    return new Point(directionX, directionY);
}
export function directionToRadians(direction) {
    return Math.atan2(direction.x, direction.y);
}
export function directionToAngle(direction) {
    return (directionToRadians(direction) * 180) / PI;
}
export function isPositiveInteger(num) {
    return num > 0 && Number.isInteger(num);
}
export function toPoint(other) {
    return new Point(other.x, other.y);
}
export class Point {
    constructor(x, y) {
        var _a;
        this.x = x !== null && x !== void 0 ? x : 0;
        this.y = (_a = y !== null && y !== void 0 ? y : x) !== null && _a !== void 0 ? _a : 0;
    }
    copy() {
        return new Point(this.x, this.y);
    }
    plus(other) {
        const x = this.x + other.x;
        const y = this.y + other.y;
        return new Point(x, y);
    }
    minus(other) {
        const x = this.x - other.x;
        const y = this.y - other.y;
        return new Point(x, y);
    }
    scale(factor) {
        const x = this.x * factor;
        const y = this.y * factor;
        return new Point(x, y);
    }
    divide(factor) {
        const x = this.x / factor;
        const y = this.y / factor;
        return new Point(x, y);
    }
    normalize() {
        return this.divide(this.length);
    }
    get lengthSquared() {
        return this.x * this.x + this.y * this.y;
    }
    get length() {
        return Math.sqrt(this.lengthSquared);
    }
}
export function normalizedDirectionBetweenPoints(start, end) {
    return normalize(end.minus(start));
}
export function cutValueToPrecision(value, precision) {
    if (isNaN(value) || value == null || isNaN(precision) || precision == null) {
        return null;
    }
    const sign = value < 0 ? -1 : 1;
    const scale = Math.pow(10, precision);
    // We have to add a bias because some mathematical operations are just not right in javascript runtime
    // e.g.: See (4.89 * 100) in Javascript Console...
    const bias = 1 / (scale * scale * 10);
    value = Math.floor(Math.abs(value) * scale + bias) / scale;
    return value * sign;
}
